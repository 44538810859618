.chat-window {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow-y: scroll;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Hide scrollbar when not needed but show it when scrolling */
.chat-window::-webkit-scrollbar {
    width: 4px; /* Adjust the width of the scrollbar */
    background-color: transparent; /* Make the scrollbar track transparent */
}

.chat-window::-webkit-scrollbar-track {
    background-color: transparent; /* Make the scrollbar track transparent */
    border-radius: 10px;
}

.chat-window::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* The color of the scrollbar thumb */
    border-radius: 10px; /* Rounded edges for the scrollbar thumb */
}

.chat-window::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3); /* Slightly darker on hover */
}

.user-message {
    text-align: right;
    margin-bottom: 10px;
}

.bot-message {
    text-align: left;
    margin-bottom: 10px;
}

.message-bubble {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 15px;
    max-width: 60%;
    line-height: 1.5;
}

.user-message .message-bubble {
    background-color: #e0f7fa;
    color: #00695c;
    border-radius: 15px 15px 0 15px;
    margin-left: auto;
}

.bot-message .message-bubble {
    background-color: #e3f2fd;
    color: #0d47a1;
    border-radius: 15px 15px 15px 0;
    margin-right: auto;
}
