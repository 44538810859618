.chat-container {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 70%;
    margin: 50px auto;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #f9f9f9;
}

.chat-window {
    flex: 1;
    border: 1px solid #ccc;
    padding: 10px;
    overflow-y: scroll;
    margin-bottom: 10px;
    background-color: #fff;
}

.chat-input {
    display: flex;
    height: 6rem;
}

.chat-input input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
}

.chat-input button.send-button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-family: inherit; /* Ensures the font is consistent with the rest of the app */
}
