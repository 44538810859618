/* =================================================================== 
 *  Keep It Simple Main Stylesheet
 *  Template Ver. 3.0.0
 *  12-14-2019
 *  ------------------------------------------------------------------
 *
 *  TOC:
 *  # custom block grid STACK breakpoints
 *  # base style overrides
 *    ## links 
 *  # typography & general theme styles
 *    ## lists 
 *    ## responsive video container
 *    ## floated image
 *    ## tables
 *    ## spacing 
 *  # preloader
 *  # forms 
 *    ## style placeholder text
 *    ## change autocomplete styles in chrome
 *  # buttons
 *  # additional components 
 *    ## additional typo styles 
 *    ## alert box 
 *    ## pagination 
 *  # common and reusable styles 
 *  # site header 
 *    ## header-logo
 *    ## main navigation
 *    ## mobile menu toggle 
 *    ## main navigation on large screens
 *  # content 
 *  # sidebar 
 *  # footer 
 *    ## footer social
 *    ## photostream
 *    ## copyright
 *    ## go top
 *  # blog styles and blog components
 *    ## entry meta
 *    ## entry tags
 *    ## entry post nav
 *    ## post-list-nav
 *    ## comments
 *
 * =================================================================== */



/* ===================================================================
 * # custom block grid STACK breakpoints 
 *
 * ------------------------------------------------------------------- */
@media screen and (max-width:1000px) {
    .block-1000-full>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width:900px) {
    .block-900-full>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}



/* ===================================================================
 * # base style overrides
 *
 * ------------------------------------------------------------------- */
html {
    font-size: 10px;
}

@media screen and (max-width:400px) {
    html {
        font-size: 9.375px;
    }
}

html, body {
    height: 100%;
}

body {
    background: #f4f0e5;
    font-family: "Merriweather", serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2;
    color: #3e3e3e;
    margin: 0;
    padding: 0;
    position: relative;
}

/* ------------------------------------------------------------------- 
 * ## links 
 * ------------------------------------------------------------------- */
a {
    color: #84be5b;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a:hover, a:focus, a:active {
    color: #656986;
}

a:hover, a:active {
    outline: 0;
}



/* ===================================================================
 * # typography & general theme styles
 * 
 * ------------------------------------------------------------------- */

/* type scale - ratio 1:125(major second), base: 16px
 * -------------------------------------------------------------------
 *   3.653em (58.45px)
 *   3.247em (51.96px)
 *   2.887em (46.18px)
 *   2.566em (41.05px)
 *   2.281em (36.49px)
 *   2.027em (32.44px)
 *   1.802em (28.83px)
 *   1.602em (25.63px)
 *   1.424em (22.78px)
 *   1.266em (20.25px)
 *   1.125em (18.00px)
 *   1em     (16.00px)
 *   0.889em (14.22px)
 *   0.79em  (12.64px)
 *   0.702em (11.24px)
 * -------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #272727;
    -webkit-font-variant-ligatures: common-ligatures;
    font-variant-ligatures: common-ligatures;
    text-rendering: optimizeLegibility;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    margin-top: 6rem;
    margin-bottom: 1.6rem;
}

@media screen and (max-width:600px) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        margin-top: 5.6rem;
    }
}

h5, .h5, h6, .h6 {
    margin-top: 4rem;
    margin-bottom: 1.2rem;
}

@media screen and (max-width:600px) {
    h5, .h5, h6, .h6 {
        margin-top: 3.6rem;
        margin-bottom: 0.8rem;
    }
}

h1, .h1 {
    font-size: 3.649rem;
    line-height: 1.206;
    letter-spacing: -.05rem;
}

@media screen and (max-width:600px) {
    h1, .h1 {
        font-size: 3.244rem;
    }
}

h2, .h2 {
    font-size: 2.883rem;
    line-height: 1.249;
}

h3, .h3 {
    font-size: 2.563rem;
    line-height: 1.249;
}

h4, .h4 {
    font-size: 2.278rem;
    line-height: 1.229;
}

h5, .h5 {
    font-size: 2.025rem;
    line-height: 1.383;
}

h6, .h6 {
    font-weight: 700;
    font-size: 1.422rem;
    line-height: 1.406;
    text-transform: uppercase;
    letter-spacing: .2rem;
}

p img {
    margin: 0;
}

p.lead {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-size: 2.025rem;
    line-height: 1.975;
    color: #272727;
}

@media screen and (max-width:600px) {
    p.lead {
        font-size: 1.8rem;
    }
}

em, i, strong, b {
    font-size: inherit;
    line-height: inherit;
}

em, i {
    font-family: "Merriweather", serif;
    font-style: italic;
}

strong, b {
    font-family: "Merriweather", serif;
    font-weight: 700;
}

small {
    font-size: 1.264rem;
    font-weight: 500;
    line-height: 1.582;
    line-height: inherit;
}

blockquote {
    margin: 4rem 0;
    padding: 4rem 4rem;
    border-left: 4px solid #272727;
    position: relative;
}

@media screen and (max-width:600px) {
    blockquote {
        padding: 3.2rem 3.2rem;
    }
}

@media screen and (max-width:400px) {
    blockquote {
        padding: 2.8rem 2.8rem;
    }
}

blockquote p {
    font-family: "Merriweather", serif;
    font-weight: 400;
    padding: 0;
    font-size: 2.278rem;
    line-height: 1.756;
    color: #272727;
}

@media screen and (max-width:600px) {
    blockquote p {
        font-size: 2.025rem;
    }
}

blockquote cite {
    display: block;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 1.264rem;
    line-height: 1.266;
    font-style: normal;
}

blockquote cite:before {
    content: "\2014 \0020";
}

blockquote cite, blockquote cite a, blockquote cite a:visited {
    color: #3e3e3e;
    border: none;
}

abbr {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: .2rem;
    color: #272727;
}

var, kbd, samp, code, pre {
    font-family: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}

pre {
    padding: 2.4rem 3.2rem 3.2rem;
    background: #efefef;
    overflow-x: auto;
}

code {
    font-size: 1.4rem;
    margin: 0 .2rem;
    padding: .4rem .8rem;
    white-space: nowrap;
    background: #efefef;
    border: 1px solid #d3d3d3;
    color: #272727;
    border-radius: 3px;
}

pre>code {
    display: block;
    white-space: pre;
    line-height: 2;
    padding: 0;
    margin: 0;
}

pre.prettyprint>code {
    border: none;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
    text-decoration: none;
}

mark {
    background: #fff099;
    color: #000000;
}

hr {
    border: solid #efefef;
    border-width: 1px 0 0;
    clear: both;
    margin: 8rem 0 9.6rem;
    height: 0;
}

/* ------------------------------------------------------------------- 
 * ## lists 
 * ------------------------------------------------------------------- */
ol {
    list-style: decimal;
}

ul {
    list-style: disc;
}

li {
    display: list-item;
}

ol, ul {
    margin-left: 1.6rem;
}

ul li {
    padding-left: .4rem;
}

ul ul, ul ol, ol ol, ol ul {
    margin: .8rem 0 .8rem 1.6rem;
}

ul.disc li {
    display: list-item;
    list-style: none;
    padding: 0 0 0 .8rem;
    position: relative;
}

ul.disc li::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #84be5b;
    position: absolute;
    left: -16px;
    top: 11px;
    vertical-align: middle;
}

dt {
    margin: 0;
    color: #84be5b;
}

dd {
    margin: 0 0 0 2rem;
}

/* ------------------------------------------------------------------- 
 * ## responsive video container
 * ------------------------------------------------------------------- */
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-container iframe, 
.video-container object, 
.video-container embed, 
.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ------------------------------------------------------------------- 
 * ## floated image
 * ------------------------------------------------------------------- */
img.h-pull-right {
    margin: 1.2rem 0 1.2rem 2.8rem;
}

img.h-pull-left {
    margin: 1.2rem 2.8rem 1.2rem 0;
}

/* ------------------------------------------------------------------- 
 * ## tables
 * ------------------------------------------------------------------- */
table {
    border-width: 0;
    width: 100%;
    max-width: 100%;
    font-family: "Merriweather", serif;
    border-collapse: collapse;
}

th, td {
    padding: 1.5rem 3.2rem;
    text-align: left;
    border-bottom: 1px solid #efefef;
}

th {
    color: #000000;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

th:first-child, td:first-child {
    padding-left: 0;
}

th:last-child, td:last-child {
    padding-right: 0;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* ------------------------------------------------------------------- 
 * ## spacing 
 * ------------------------------------------------------------------- */
button, .btn {
    margin-bottom: 1.6rem;
}

fieldset {
    margin-bottom: 1.6rem;
}

input, 
textarea, 
select, 
pre, 
blockquote, 
figure, 
table, 
p, 
ul, 
ol, 
dl, 
form, 
.video-container, 
.ss-custom-select {
    margin-bottom: 3.2rem;
}



/* ===================================================================
 * # preloader
 *
 * ------------------------------------------------------------------- */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 500;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.no-js #preloader, .oldie #preloader {
    display: none;
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 6px;
    height: 6px;
    padding: 0;
    display: inline-block;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

#loader>div {
    content: "";
    background: #000000;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
}

#loader>div:nth-of-type(1) {
    left: 15px;
}

#loader>div:nth-of-type(3) {
    left: -15px;
}

/* dots jump */
.dots-jump>div {
    -webkit-animation: dots-jump 1.2s infinite ease;
    animation: dots-jump 1.2s infinite ease;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.dots-jump>div:nth-of-type(1) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.dots-jump>div:nth-of-type(3) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

@-webkit-keyframes dots-jump {
    0% {
        top: 0;
    }
    40% {
        top: -6px;
    }
    80% {
        top: 0;
    }
}

@keyframes dots-jump {
    0% {
        top: 0;
    }
    40% {
        top: -6px;
    }
    80% {
        top: 0;
    }
}

/* dots fade */
.dots-fade>div {
    -webkit-animation: dots-fade 1.6s infinite ease;
    animation: dots-fade 1.6s infinite ease;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.dots-fade>div:nth-of-type(1) {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.dots-fade>div:nth-of-type(3) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

@-webkit-keyframes dots-fade {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 0.2;
    }
    80% {
        opacity: 1;
    }
}

@keyframes dots-fade {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 0.2;
    }
    80% {
        opacity: 1;
    }
}

/* dots pulse */
.dots-pulse>div {
    -webkit-animation: dots-pulse 1.2s infinite ease;
    animation: dots-pulse 1.2s infinite ease;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.dots-pulse>div:nth-of-type(1) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.dots-pulse>div:nth-of-type(3) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

@-webkit-keyframes dots-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    40% {
        -webkit-transform: scale(1.1);
        transform: scale(1.3);
    }
    80% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes dots-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    40% {
        -webkit-transform: scale(1.1);
        transform: scale(1.3);
    }
    80% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}



/* ===================================================================
 * # forms 
 *
 * ------------------------------------------------------------------- */
fieldset {
    border: none;
}

input[type="email"], 
input[type="number"], 
input[type="search"], 
input[type="text"], 
input[type="tel"], 
input[type="url"], 
input[type="password"], 
textarea, 
select {
    display: block;
    height: 6rem;
    padding: 1.3rem 23px 1.5rem;
    border: 0;
    outline: none;
    color: #3e3e3e;
    font-family: "Open Sans", sans-serif;
    font-size: 1.422rem;
    line-height: 3.2rem;
    max-width: 100%;
    background-color: #efefef;
    border: 1px solid transparent;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 4px;
}

.ss-custom-select {
    position: relative;
    padding: 0;
}

.ss-custom-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    margin: 0;
    line-height: 3rem;
    vertical-align: middle;
}

.ss-custom-select select option {
    padding-left: 2rem;
    padding-right: 2rem;
}

.ss-custom-select select::-ms-expand {
    display: none;
}

.ss-custom-select::after {
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    margin-top: -7px;
    pointer-events: none;
    position: absolute;
    right: 2.4rem;
    top: 50%;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

textarea {
    min-height: 25.6rem;
}

input[type="email"]:focus, 
input[type="number"]:focus, 
input[type="search"]:focus, 
input[type="text"]:focus, 
input[type="tel"]:focus, 
input[type="url"]:focus, 
input[type="password"]:focus, 
textarea:focus, 
select:focus {
    color: #000000;
    -webkit-box-shadow: 0 0 5px #6ba441;
    box-shadow: 0 0 5px #6ba441;
    border: 1px solid #6ba441;
}

label, legend {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.714;
    margin-bottom: .8rem;
    color: #272727;
    display: block;
}

input[type="checkbox"], input[type="radio"] {
    display: inline;
}

label>.label-text {
    display: inline-block;
    margin-left: 1rem;
    font-family: "Open Sans", sans-serif;
    line-height: inherit;
}

label>input[type="checkbox"], 
label>input[type="radio"] {
    margin: 0;
    position: relative;
    top: .2rem;
}

/* ------------------------------------------------------------------- 
 * ## style placeholder text
 * ------------------------------------------------------------------- */
::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #8c8c8c;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8c8c8c;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8c8c8c;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #8c8c8c;
}

.placeholder {
    color: #8c8c8c !important;
}

/* ------------------------------------------------------------------- 
 * ## change autocomplete styles in chrome
 * ------------------------------------------------------------------- */
input:-webkit-autofill, 
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus input:-webkit-autofill, 
textarea:-webkit-autofill, 
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, 
select:-webkit-autofill, 
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #9fcc7f;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}



/* ===================================================================
 * # buttons 
 *
 * ------------------------------------------------------------------- */
.btn, 
button, 
input[type="submit"], 
input[type="reset"], 
input[type="button"] {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 1.264rem;
    text-transform: uppercase;
    letter-spacing: .5rem;
    height: 6rem;
    line-height: 5.6rem;
    padding: 0 3.2rem;
    margin: 0 .4rem 1.6rem 0;
    color: #000000;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 4px;
    background-color: #d3d3d3;
    border: 0.2rem solid #d3d3d3;
}

.btn:hover, 
button:hover, 
input[type="submit"]:hover, 
input[type="reset"]:hover, 
input[type="button"]:hover, 
.btn:focus, button:focus, 
input[type="submit"]:focus, 
input[type="reset"]:focus, 
input[type="button"]:focus {
    background-color: #000000;
    border-color: #000000;
    color: #ffffff;
    outline: 0;
}

/* button primary
 * ------------------------------------------------- */
.btn.btn--primary, 
button.btn--primary, 
input[type="submit"].btn--primary, 
input[type="reset"].btn--primary, 
input[type="button"].btn--primary {
    background: #84be5b;
    border-color: #84be5b;
    color: #ffffff;
}

.btn.btn--primary:hover, 
button.btn--primary:hover, 
input[type="submit"].btn--primary:hover, 
input[type="reset"].btn--primary:hover, 
input[type="button"].btn--primary:hover, 
.btn.btn--primary:focus, 
button.btn--primary:focus, 
input[type="submit"].btn--primary:focus, 
input[type="reset"].btn--primary:focus, 
input[type="button"].btn--primary:focus {
    background: #000000;
    border-color: #000000;
}

/* button modifiers
 * ------------------------------------------------- */
.btn.h-full-width, 
button.h-full-width {
    width: 100%;
    margin-right: 0;
}

.btn--small, button.btn--small {
    height: 5.6rem !important;
    line-height: 5.2rem !important;
}

.btn--medium, button.btn--medium {
    height: 6.4rem !important;
    line-height: 6rem !important;
}

.btn--large, button.btn--large {
    height: 6.8rem !important;
    line-height: 6.4rem !important;
}

.btn--stroke, button.btn--stroke {
    background: transparent !important;
    border: 0.2rem solid #000000;
    color: #000000;
}

.btn--stroke:hover, button.btn--stroke:hover {
    background: #000000 !important;
    border: 0.2rem solid #000000;
    color: #ffffff;
}

.btn--pill, button.btn--pill {
    padding-left: 3.2rem !important;
    padding-right: 3.2rem !important;
    border-radius: 1000px !important;
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}



/* =================================================================== 
 * # additional components 
 *
 * ------------------------------------------------------------------- */

/* ------------------------------------------------------------------- 
 * ## additional typo styles 
 * ------------------------------------------------------------------- */
.drop-cap:first-letter {
    float: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 6em;
    line-height: 1;
    padding: 0 0.125em 0 0;
    text-transform: uppercase;
    background: transparent;
    color: #000000;
}

/* line definition style 
 * ----------------------------------------------- */
.lining dt, .lining dd {
    display: inline;
    margin: 0;
}

.lining dt+dt:before, .lining dd+dt:before {
    content: "\A";
    white-space: pre;
}

.lining dd+dd:before {
    content: ", ";
}

.lining dd+dd:before {
    content: ", ";
}

.lining dd:before {
    content: ": ";
    margin-left: -0.2em;
}

/* dictionary definition style 
 * ----------------------------------------------- */
.dictionary-style dt {
    display: inline;
    counter-reset: definitions;
}

.dictionary-style dt+dt:before {
    content: ", ";
    margin-left: -0.2em;
}

.dictionary-style dd {
    display: block;
    counter-increment: definitions;
}

.dictionary-style dd:before {
    content: counter(definitions, decimal) ". ";
}

/** 
 * Pull Quotes
 * -----------
 * markup:
 *
 * <aside class="pull-quote">
 *    <blockquote>
 *      <p></p>
 *    </blockquote>
 * </aside>
 *
 * --------------------------------------------------------------------- */
.pull-quote {
    position: relative;
    padding: 2.4rem 0;
}

.pull-quote blockquote {
    background-color: #efefef;
    border: none;
    margin: 0;
    padding-top: 9.6rem;
    position: relative;
}

.pull-quote blockquote:before {
    content: "";
    display: block;
    height: 3.2rem;
    width: 3.2rem;
    background-repeat: no-repeat;
    background: center center;
    background-size: contain;
    background-image: url(/public/images/icons/icon-quote.svg);
    position: absolute;
    top: 4rem;
    left: 4rem;
}

/** 
 * Stats Tab
 * ---------
 * markup:
 *
 * <ul class="stats-tabs">
 *    <li><a href="#">[value]<em>[name]</em></a></li>
 *  </ul>
 *
 * Extend this object into your markup.
 *
 * --------------------------------------------------------------------- */
.stats-tabs {
    padding: 0;
    margin: 3.2rem 0;
}

.stats-tabs li {
    display: inline-block;
    margin: 0 1.6rem 3.2rem 0;
    padding: 0 1.5rem 0 0;
    border-right: 1px solid #e0e0e0;
}

.stats-tabs li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.stats-tabs li a {
    display: inline-block;
    font-size: 2.5rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    line-height: 1.92;
    border: none;
    color: #000000;
}

.stats-tabs li a:hover {
    color: #84be5b;
}

.stats-tabs li a em {
    display: block;
    margin: .4rem 0 0 0;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    line-height: 1.6rem;
    font-weight: normal;
    font-style: normal;
    color: #a7a7a7;
}

/* ------------------------------------------------------------------- 
 * ## alert box 
 * ------------------------------------------------------------------- */
.alert-box {
    padding: 2.4rem 4rem 2.4rem 3.2rem;
    position: relative;
    margin-bottom: 3.2rem;
    border-radius: 3px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.422rem;
    line-height: 1.688;
}

.alert-box__close {
    position: absolute;
    display: block;
    right: 1.6rem;
    top: 1.6rem;
    cursor: pointer;
    width: 12px;
    height: 12px;
}

.alert-box__close::before, .alert-box__close::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 2px;
    height: 12px;
    top: 0;
    left: 5px;
}

.alert-box__close::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.alert-box__close::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.alert-box--error {
    background-color: #ffd1d2;
    color: #dd4043;
}

.alert-box--error .alert-box__close::before, 
.alert-box--error .alert-box__close::after {
    background-color: #dd4043;
}

.alert-box--success {
    background-color: #c8e675;
    color: #637533;
}

.alert-box--success .alert-box__close::before, 
.alert-box--success .alert-box__close::after {
    background-color: #637533;
}

.alert-box--info {
    background-color: #d5ebfb;
    color: #387fb2;
}

.alert-box--info .alert-box__close::before, 
.alert-box--info .alert-box__close::after {
    background-color: #387fb2;
}

.alert-box--notice {
    background-color: #fff099;
    color: #827217;
}

.alert-box--notice .alert-box__close::before, 
.alert-box--notice .alert-box__close::after {
    background-color: #827217;
}

/* -------------------------------------------------------------------
 * ## pagination 
 * ------------------------------------------------------------------- */
.pgn {
    margin: 3.2rem auto 3.2rem;
    text-align: center;
}

.pgn ul {
    display: inline-block;
    list-style: none;
    margin-left: 0;
    position: relative;
    padding: 0 6rem;
}

.pgn ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.pgn__num {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 3.2rem;
    display: inline-block;
    padding: .2rem 1.2rem;
    height: 3.6rem;
    margin: .2rem .2rem;
    color: #272727;
    border-radius: 4px;
    -webkit-transition: all, .3s, ease-in-out;
    transition: all, .3s, ease-in-out;
}

.pgn__num:hover {
    background: #efefef;
    color: #000000;
}

.pgn .current, .pgn .current:hover {
    background-color: #272727;
    color: #ffffff;
}

.pgn .inactive, .pgn .inactive:hover {
    opacity: 0.4;
    cursor: default;
}

.pgn__prev, .pgn__next {
    display: block;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center;
    height: 3.6rem;
    width: 4.8rem;
    line-height: 2rem;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    opacity: 1;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    -webkit-transition: all, .2s, ease-in-out;
    transition: all, .2s, ease-in-out;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
}

.pgn__prev:hover, .pgn__next:hover {
    background-color: #efefef;
}

.pgn__prev {
    background-image: url("/public/images/icons/icon-arrow-left.svg");
    left: 0;
}

.pgn__next {
    background-image: url("/public/images/icons/icon-arrow-right.svg");
    right: 0;
}

.pgn__prev.inactive, .pgn__next.inactive {
    opacity: 0.4;
    cursor: default;
}

.pgn__prev.inactive:hover, .pgn__next.inactive:hover {
    background-color: transparent;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * pagination
 * ------------------------------------------------------------------- */
@media screen and (max-width:600px) {
    .pgn ul {
        padding: 0 5.2rem;
    }
}



/* ===================================================================
 * # common and reusable styles 
 *
 * ------------------------------------------------------------------- */
.wide {
    max-width: 1400px;
}

.wider {
    max-width: 1600px;
}

.narrow {
    max-width: 1000px;
}



/* ===================================================================
 * # site header 
 *
 * ------------------------------------------------------------------- */
.s-header {
    width: 100%;
    background-color: #faf7ee;
    position: relative;
}

.s-header::before {
    z-index: 101;
    content: "";
    display: block;
    width: 100%;
    height: .6rem;
    background-color: #9fcc7f;
    position: absolute;
    top: 0;
    left: 0;
}

.s-header__content {
    background-color: #faf7ee;
    background-image: url(/public/images/header-content-bg_@2x.jpg);
    background-size: 361px 256px;
    background-repeat: no-repeat;
    background-position: right top;
    min-height: 30rem;
    padding-top: 12rem;
    padding-bottom: 8rem;
    border-bottom: 1px dashed #efefef;
    position: relative;
}

/* -------------------------------------------------------------------
 * ## header-logo 
 * ------------------------------------------------------------------- */
.s-header__logotext {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: Georgia, serif;
    font-weight: 400;
    font-size: 7.2rem;
    line-height: 1;
    letter-spacing: -.45rem;
}

.s-header__logotext a {
    color: #9fcc7f;
}

.s-header__tagline {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
    color: #c3c3c3;
    position: relative;
    top: .8rem;
    left: .8rem;
}

/* -------------------------------------------------------------------
 * ## main navigation 
 * ------------------------------------------------------------------- */
.s-header__nav-wrap, .s-header__nav {
    margin: 0;
    padding: 0;
}

/* nav-wrap */
.s-header__nav-wrap {
    z-index: 1;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
    background-color: #F5F4F3;
    border-bottom: 1px solid #EAE8E8;
    position: absolute;
    top: .6rem;
    left: 0;
}

.s-header__nav {
    list-style: none;
    min-height: 6rem;
    width: auto;
    margin-left: 20px;
    position: relative;
}

.s-header__nav li {
    display: inline-block;
    height: 6rem;
    margin: 0;
    padding-top: 1.2rem;
    padding-left: 0;
    position: relative;
}

.s-header__nav li.has-children>a {
    padding-right: 2.6rem;
    position: relative;
}

.s-header__nav li.has-children>a::after {
    border-bottom: 1px solid #6ba441;
    border-right: 1px solid #6ba441;
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    margin-top: -3px;
    pointer-events: none;
    position: absolute;
    right: 1.6rem;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.s-header__nav>li {
    float: left;
}

.s-header__nav li a {
    display: block;
    padding: 0 1.4rem;
    line-height: calc(6rem - 1.2rem);
    text-decoration: none;
    color: #666666;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
}

.s-header__nav li a:hover, .s-header__nav li a:focus {
    color: #000000;
}

.s-header__nav li a:active {
    background-color: transparent !important;
}

.s-header__nav li.current>a {
    background-color: #ffffff;
    border-right: 1px solid #EAE8E8;
    border-top: 1px solid #EAE8E8;
    border-left: 1px solid #EAE8E8;
    border-radius: 3px 3px 0 0;
}

/* sub menu */
.s-header__nav ul {
    min-width: 100%;
    margin: 0;
    padding: 1.2rem 0;
    background: #F4F3F3;
    border: 1px solid #EAE8E8;
    border-top: none;
    border-radius: 0 0 3px 3px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .25s ease .1s;
    transition: all .25s ease .1s;
    position: absolute;
    top: calc(100% + 2px);
    left: 0px;
}

.s-header__nav ul li {
    padding: 0;
    display: block;
    text-align: left;
    -webkit-transition: height .25s ease .1s;
    transition: height .25s ease .1s;
    height: 0;
    overflow: hidden;
}

.s-header__nav ul li a {
    padding: 0 2rem;
    margin: 0;
    white-space: nowrap;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    line-height: 4rem;
    text-transform: none;
    letter-spacing: 0;
}

/* on hover */
.s-header__nav li:hover>ul {
    opacity: 1;
    visibility: visible;
}

.s-header__nav li:hover>ul li {
    height: 4rem;
    overflow: visible;
}

/* ------------------------------------------------------------------- 
 * ## mobile menu toggle 
 * ------------------------------------------------------------------- */
.header-menu-toggle {
    z-index: 101;
    display: none;
    background-color: #9fcc7f;
    height: 4.8rem;
    width: 5.2rem;
    line-height: 4.8rem;
    font-family: "Open Sans", sans-serif;
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
    color: #9fcc7f;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    position: absolute;
    right: 3.2rem;
    top: .6rem;
}

.header-menu-toggle:hover, .header-menu-toggle:focus {
    color: #ffffff;
}

.header-menu-toggle span {
    display: block;
    width: 2rem;
    height: 2px;
    background-color: #ffffff;
    -webkit-transition: all .5s;
    transition: all .5s;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    left: 1.6rem;
    right: auto;
    bottom: auto;
}

.header-menu-toggle span::before, .header-menu-toggle span::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: inherit;
    -webkit-transition: all .5s;
    transition: all .5s;
    position: absolute;
    left: 0;
}

.header-menu-toggle span::before {
    top: -.7rem;
}

.header-menu-toggle span::after {
    bottom: -.7rem;
}

.header-menu-toggle.is-clicked span {
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: all .1s;
    transition: all .1s;
}

.header-menu-toggle.is-clicked span::before, .header-menu-toggle.is-clicked span::after {
    background-color: white;
}

.header-menu-toggle.is-clicked span::before {
    top: 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.header-menu-toggle.is-clicked span::after {
    bottom: 0;
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

/* ------------------------------------------------------------------- 
 * responsive:
 * header
 * ------------------------------------------------------------------- */
@media screen and (max-width:1200px) {
    .s-header__logotext {
        font-size: 7rem;
    }
    .s-header__tagline {
        font-size: 1.6rem;
    }
}

@media screen and (max-width:1000px) {
    .s-header__logotext {
        font-size: 6.8rem;
    }
}

@media screen and (max-width:800px) {
    .s-header__content {
        padding-top: 9.6rem;
        padding-bottom: 12rem;
        min-height: 0;
        background-size: 310px 220px;
    }
    .header-menu-toggle {
        display: block;
    }
    .s-header__nav-wrap {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding-top: 8rem;
        font-weight: 600;
        font-size: 1.8rem;
        text-transform: none;
        letter-spacing: 0;
    }
    .s-header__nav-wrap>.row {
        width: 100%;
    }
    .s-header__nav {
        min-height: 0;
        width: 100%;
        margin: 6rem 6rem 8rem;
    }
    .s-header__nav>li {
        float: none;
        display: block;
        height: auto;
        padding-top: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
    .s-header__nav>li:first-child {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
    }
    .s-header__nav li.has-children>a::after {
        border-bottom: 1px solid #000000;
        border-right: 1px solid #000000;
    }
    .s-header__nav li.has-children>a.sub-menu-is-open::after {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }
    .s-header__nav li a {
        padding: 0;
    }
    .s-header__nav>li a {
        display: block;
        color: #272727;
        line-height: 6rem;
    }
    .s-header__nav li.current>a {
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: #84be5b;
    }
    .s-header__nav ul {
        opacity: 1;
        visibility: visible;
        display: none;
        padding-top: 0;
        -webkit-transition: none;
        transition: none;
        background: transparent;
        border: none;
        position: static;
    }
    .s-header__nav ul li {
        height: auto;
        overflow: auto;
    }
    .s-header__nav ul li a {
        padding: 0 0 0 .8rem;
    }
    body.menu-is-open {
        overflow: hidden;
    }
    .menu-is-open .s-header__nav-wrap {
        -webkit-transition: all .3s;
        transition: all .3s;
        opacity: 1;
        visibility: visible;
    }
}

@media screen and (max-width:600px) {
    .s-header__content {
        background-position: center top;
        text-align: center;
    }
    .s-header__logotext {
        font-size: 6.4rem;
    }
    .s-header__nav-wrap>.row {
        padding: 0;
    }
}

@media screen and (max-width:400px) {
    .s-header__logotext {
        font-size: 6rem;
    }
    .s-header__nav {
        margin: 6rem 4rem 4.8rem;
    }
}

/* ------------------------------------------------------------------- 
 * ## main navigation on large screens
 * ------------------------------------------------------------------- */
@media only screen and (min-width:801px) {
    .s-header__nav li.has-children:hover>a::after, 
    .s-header__nav li.has-children:focus>a::after {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }
    .s-header__nav ul {
        display: block !important;
    }
}



/* ===================================================================
 * # content 
 *
 * ------------------------------------------------------------------- */
.s-content {
    background: #faf7ee;
    padding: 4.4rem 0 3.6rem 0;
}

.s-content__main {
    padding-right: 4.6rem;
}

.ss-single .s-content__main {
    padding-bottom: 8rem;
}

.page-content {
    padding-bottom: 2rem;
    margin-bottom: 3.6rem;
}

.page-content__title {
    margin-top: 0;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * content
 * ------------------------------------------------------------------- */
@media screen and (max-width:1200px) {
    .s-content__main {
        padding-right: 3.6rem;
    }
}

@media screen and (max-width:960px) {
    .s-content__main {
        padding-right: 2.4rem;
    }
}

@media screen and (max-width:900px) {
    .s-content__main, .s-content__sidebar {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .s-content__main {
        padding-right: 16px;
    }
    .s-content__sidebar {
        margin-top: 6rem;
    }
}

@media screen and (max-width:600px) {
    .s-content__main {
        padding-right: 10px;
    }
    .page-content__title {
        text-align: center;
    }
}

@media screen and (max-width:400px) {
    .s-content__main {
        padding-right: 0;
    }
}



/* ===================================================================
 * # sidebar 
 *
 * ------------------------------------------------------------------- */
.s-content__sidebar h3 {
    margin-top: 0;
}

.s-content__sidebar ul {
    list-style: none;
    margin-left: 0;
}

.s-content__sidebar hr, .s-content__sidebar .widget {
    margin-bottom: 2.4rem;
}

/* link List */

.s-content__sidebar .link-list {
    padding: 0;
    margin: 2rem 0 3.2rem 0;
}

.s-content__sidebar .link-list li {
    padding: .8rem 0;
    margin: 0;
    border-bottom: 1px solid #efefef;
}

.s-content__sidebar .link-list li:first-child {
    border-top: 1px solid #efefef;
}

.s-content__sidebar .link-list li a {
    color: #3e3e3e;
}

.s-content__sidebar .link-list li a:hover, .s-content__sidebar .link-list li a:focus {
    color: #9fcc7f;
}

/* tag cloud */

.s-content__sidebar .tagcloud {
    margin: 2rem 0 3.2rem -1.2rem;
    padding: 0;
}

.s-content__sidebar .tagcloud a {
    background-color: #efefef;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 2.4rem;
    display: inline-block;
    margin: 0 0 1.2rem 1rem;
    padding: .8rem 1.6rem;
    position: relative;
    text-transform: uppercase;
    border-radius: 3px;
    text-decoration: none;
    letter-spacing: .2rem;
    color: #000000;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.s-content__sidebar .tagcloud a:hover, .s-content__sidebar .tagcloud a:focus {
    color: #ffffff;
    background: #9fcc7f;
}

/* search  */

.widget--search h3 {
    display: none;
}

.widget--search form {
    position: relative;
    margin: 0;
}

.widget--search .text-search {
    padding-right: 50px;
    border: none;
    width: 100%;
    min-width: 150px;
    border-radius: 4px;
}

.widget--search .submit-search {
    background-color: transparent !important;
    background-image: url(/public/images/icons/icon-search.svg);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    min-height: 2rem;
    padding: 0;
    margin: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    position: absolute;
    top: 50%;
    right: 2rem;
}

.s-content__sidebar .widget--search {
    margin-bottom: 4rem;
}

/* categories  */

.widget--categories ul {
    margin-top: 1.2rem;
}

.widget--categories ul li {
    padding-left: 0;
}



/* ===================================================================
 * # footer 
 *
 * ------------------------------------------------------------------- */
.s-footer {
    padding-top: 4.8rem;
    margin-bottom: 4.2rem;
    font-size: 1.422rem;
    line-height: 2.8rem;
    position: relative;
}

.s-footer h3 {
    margin-top: 0;
    margin-bottom: 1.6rem;
}

.s-footer p {
    margin-bottom: 2.4rem;
}

.s-footer__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.s-footer__list li {
    margin: 0;
    padding-left: 0;
    line-height: 24px;
}

.s-footer__list li a {
    color: #3e3e3e;
}

.s-footer__list li a:hover, .s-footer__list li a:focus {
    color: #84be5b;
}

.s-footer__info {
    padding-right: 3.2rem;
}

/* ------------------------------------------------------------------- 
 * ## footer social
 * ------------------------------------------------------------------- */
.s-footer__social {
    margin: 1.6rem auto 4.8rem;
    padding: 0;
    text-align: center;
}

.s-footer__social li {
    display: inline-block;
    font-size: 3.2rem;
    line-height: 4.8rem;
    padding: 0;
    margin: 0 1.6rem;
}

.s-footer__social li a {
    color: #3e3e3e;
}

.s-footer__social li a:hover, .s-footer__social li a:focus {
    color: #84be5b;
}

/* ------------------------------------------------------------------- 
 * ## photostream
 * ------------------------------------------------------------------- */
.s-footer .photostream {
    list-style: none;
    margin: 1.2rem 0 2.4rem -1.6rem;
    padding: 0;
    overflow: hidden;
}

.s-footer .photostream li {
    display: inline-block;
    margin: 0 0 1.2rem 1.2rem;
    padding: 0;
}

.s-footer .photostream li a {
    display: block;
    height: 6rem;
    width: 6rem;
    background-color: #ffffff;
    padding: .9rem;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
}

.s-footer .photostream li a img {
    vertical-align: bottom;
}

/* ------------------------------------------------------------------- 
 * ## copyright
 * ------------------------------------------------------------------- */
.ss-copyright {
    font-family: "Open Sans", sans-serif;
    padding: 3.2rem 1.6rem .8rem 1.6rem;
    width: 100%;
    margin-bottom: 25px;
}

.ss-copyright span {
    display: inline-block;
}

.ss-copyright span::after {
    content: "|";
    display: inline-block;
    padding: 0 1rem 0 1.2rem;
    color: rgba(0, 0, 0, 0.3);
}

.ss-copyright span:last-child::after {
    display: none;
}

/* ------------------------------------------------------------------- 
 * ## go top
 * ------------------------------------------------------------------- */
.ss-go-top {
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0);
    -webkit-transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: fixed;
    bottom: 4.4rem;
    right: 4rem;
}

.ss-go-top a {
    text-decoration: none;
    border: 0 none;
    display: block;
    height: 5.6rem;
    width: 5.6rem;
    border-radius: 50%;
    background-color: #84be5b;
    -webkit-transition: all .3s;
    transition: all .3s;
    position: relative;
}

.ss-go-top a:hover, .ss-go-top a:focus {
    background-color: #000000;
}

.ss-go-top svg {
    height: 1.2rem;
    width: 1.2rem;
    position: absolute;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    top: 50%;
}

.ss-go-top svg path {
    fill: #ffffff;
}

.ss-go-top.link-is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* ------------------------------------------------------------------- 
 * responsive:
 * footer
 * ------------------------------------------------------------------- */
@media screen and (max-width:900px) {
    .s-footer__bottom>.column {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .s-footer__info {
        margin-bottom: 1.6rem;
    }
    .ss-copyright {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        text-align: center;
        margin-top: 3.2rem;
    }
}

@media screen and (max-width:800px) {
    .s-footer__social li {
        font-size: 2.8rem;
        margin: 0 1.2rem;
    }
    .s-footer-list--nav li {
        display: inline-block;
        margin-right: 1.2rem;
    }
    .s-footer-list--nav li:last-child {
        margin-right: 0;
    }
    .ss-go-top {
        right: 3.2rem;
    }
    .ss-go-top a {
        height: 4.8rem;
        width: 4.8rem;
    }
}

@media screen and (max-width:600px) {
    .ss-copyright span {
        display: block;
    }
    .ss-copyright span::after {
        display: none;
    }
}

@media screen and (max-width:400px) {
    .ss-go-top {
        right: 0rem;
        bottom: 0rem;
    }
    .ss-go-top a {
        border-radius: 4px 0 0 0;
    }
}



/* ===================================================================
 * # blog styles and blog components 
 *
 * ------------------------------------------------------------------- */
.entry {
    padding-bottom: 1.2rem;
    margin-bottom: 3.2rem;
}

.entry h2 {
    margin-top: 0;
    margin-bottom: 0;
}

.entry h2 a {
    color: #272727;
}

.entry h2 a:hover, .entry h2 a:focus {
    color: #000000;
}

.entry__header {
    border: none;
    height: auto;
}

.entry__content-media {
    margin: 3.2rem 0 4.8rem;
}

.entry__content-media img {
    vertical-align: bottom;
}

/* -------------------------------------------------------------------
 * ## entry meta
 * ------------------------------------------------------------------- */
.entry__meta ul {
    list-style: none;
    margin: .8rem 0 2rem;
    font-size: 1.422;
    color: #a7a7a7;
    line-height: 2.8rem;
}

.entry__meta ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-family: "Merriweather", serif;
}

.entry__meta ul li::after {
    content: "\2022";
    margin-left: .4rem;
    margin-right: .2rem;
}

.entry__meta ul li:last-child::after {
    display: none;
}

/* -------------------------------------------------------------------
 * ## entry tags
 * ------------------------------------------------------------------- */
.entry__tags {
    margin-top: 2rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.422rem;
    line-height: 2rem;
    text-transform: uppercase;
    letter-spacing: .03rem;
    color: #3e3e3e;
}

.entry__tags a {
    font-family: "Merriweather", serif;
    font-size: 1.6rem;
    text-transform: none;
    letter-spacing: 0;
    color: #9fcc7f;
}

/* -------------------------------------------------------------------
 * ## entry post nav
 * ------------------------------------------------------------------- */
.entry__post-nav {
    margin: 2.4rem 0 3.2rem;
    padding: 1.6rem 0 0 0;
    font-family: "Merriweather", serif;
}

.entry__post-nav li {
    display: block;
    margin: 0;
    padding: 0;
    width: 49%;
}

.entry__post-nav li a {
    color: #3e3e3e;
}

.entry__post-nav li strong {
    display: block;
}

.entry__post-nav li.next {
    float: right;
    text-align: right;
}

.entry__post-nav li.prev {
    float: left;
    text-align: left;
}

/* -------------------------------------------------------------------
 * ## post-list-nav
 * ------------------------------------------------------------------- */
.post-list-nav {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    padding-top: 2.4rem;
    margin-bottom: 4rem;
    border-top: 1px solid #efefef;
}

.post-list-nav a:first-child {
    margin-right: .8rem;
}

.post-list-nav a[rel="prev"], .post-list-nav a[rel="next"] {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 1.422rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
    color: #000000;
    background-color: #efefef;
    padding: 1.2rem 2rem;
    border-radius: 4px;
}

.post-list-nav a[rel="prev"]:hover, 
.post-list-nav a[rel="prev"]:focus, 
.post-list-nav a[rel="next"]:hover, 
.post-list-nav a[rel="next"]:focus {
    color: #ffffff;
    background-color: #9fcc7f;
}

/* -------------------------------------------------------------------
 * ## comments 
 * ------------------------------------------------------------------- */
.comments-wrap {
    padding: 6.4rem 0 0 0;
}

.comments-wrap h3 {
    margin-top: 0;
}

.comments-wrap h3 span {
    display: block;
    font-weight: 400;
    font-size: 1.6rem;
    color: #a7a7a7;
    margin-top: .4rem;
}

/* comments
 * -------------------------------------------------- */
#comments {
    padding-top: 4rem;
    padding-bottom: 1.2rem;
    border-top: 1px solid #efefef;
}

#comments ol, #comments ul {
    list-style: none;
}

.commentlist {
    margin: 6rem 0 5.6rem;
    padding: 0;
}

.commentlist>.comment {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 2rem 0 0 0;
    padding-left: 14%;
}

.comment__avatar {
    position: absolute;
    left: 0;
    display: block;
}

.comment__avatar img {
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    vertical-align: bottom;
}

.comment__info {
    position: relative;
}

.comment__info .comment__author {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.778;
    font-style: normal;
    color: #272727;
}

.comment__meta {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-size: 1.422rem;
    line-height: 2rem;
    letter-spacing: .2rem;
    text-transform: uppercase;
    color: #a7a7a7;
}

.comment__meta .comment__time, .comment__meta .comment__reply {
    display: inline-block;
}

.comment__meta .comment__time {
    margin-right: .8rem;
}

.comment__meta .comment__reply a {
    font-weight: 600;
    color: #272727;
}

.comment__text {
    clear: both;
    margin: 1.6rem 0 0 0;
}

.comment ul.children {
    margin: 0;
    padding: 0;
}

.comment ul.children li {
    padding-left: 5%;
    margin-top: calc(3.2rem + 2rem);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
}

/* comments form
 * ---------------------------- */
.comments-wrap .comment-respond {
    margin-top: 6.4rem;
}

.comment-respond form {
    padding-top: 4rem;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * blog styles and blog components
 * ------------------------------------------------------------------- */
@media screen and (max-width:900px) {
    .post-list-nav {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

@media screen and (max-width:800px) {
    .comment__avatar img {
        margin-top: 0;
        height: 5.2rem;
        width: 5.2rem;
    }
}

@media screen and (max-width:600px) {
    .entry__header {
        text-align: center;
    }
    .entry__post-nav li {
        display: block;
        width: 100%;
    }
    .entry__post-nav li.next, .entry__post-nav li.prev {
        float: none;
        text-align: left;
    }
    .commentlist>.comment {
        padding-top: 0;
        padding-left: 0;
    }
    .comment__avatar {
        display: none;
    }
    .comment ul.children li {
        margin-top: calc(3.2rem + 0rem);
    }
}

@media screen and (max-width:400px) {
    .comments-wrap, .comment-respond form {
        padding-top: 3.2rem;
    }
    .comment ul.children li {
        border-left: none;
    }
}